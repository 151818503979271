export const BACKEND = ''
//LOGIN
export const POST_LOGIN = `${BACKEND}/v1/auth/login`
export const POST_LOGIN_GOOGLE = `${BACKEND}/v1/auth/login-google`

//LAMPS
export const GET_LAMPS = `${BACKEND}/v1/lamp/list`
export const CREATE_LAMPS = `${BACKEND}/v1/lamp`

export const UPDATE_LAMP_PERMISSION = `${BACKEND}/v1/lamp/updatePermission`
