/* LAMP */
export const GET_LAMPS = "GET_LAMPS"
export const GET_LAMPS_SUCCESS = "GET_LAMPS_SUCCESS"
export const GET_LAMPS_FAIL = "GET_LAMPS_FAIL"

export const CREATE_LAMP = "CREATE_LAMPS"
export const CREATE_LAMP_SUCCESS = "CREATE_LAMPS_SUCCESS"
export const CREATE_LAMP_FAIL = "CREATE_LAMPS_FAIL"

export const UPDATE_LAMP = "UPDATE_LAMPS"
export const UPDATE_LAMP_SUCCESS = "UPDATE_LAMPS_SUCCESS"
export const UPDATE_LAMP_FAIL = "UPDATE_LAMPS_FAIL"
