import displayTime from "common/displayTime"
import React from "react"
import { UncontrolledTooltip } from "reactstrap"

const lampColumns = (toggleModalEdit, handlePing, handleYellow) => [
  {
    dataField: "mac",
    text: "Mac",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <>
        <span
          className="mr-3 text-default"
          onClick={() => toggleModalEdit(row)}
        >
          <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </span>

        <button
          onClick={() => handlePing(row.mac)}
          type="button"
          className="btn-rounded waves-effect waves-light btn btn-primary btn-sm mr-3"
        >
          Ping
        </button>

        <button
          onClick={() => handleYellow(row.topic)}
          type="button"
          className="btn-rounded waves-effect waves-light btn btn-warning btn-sm"
        >
          Yellow
        </button>
      </>
    ),
  },
  {
    dataField: "isOnline",
    text: "Online",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? "Yes" : "No"),
  },
  {
    dataField: "time",
    text: "Last ping",
    sort: true,
    formatter: (cellContent, row) =>
      cellContent ? displayTime(cellContent) : "---",
  },
  {
    dataField: "topic",
    text: "Group ID",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "groupName",
    text: "Group Name",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "userEmail",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "productType",
    text: "Product",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "isGroupOwner",
    text: "Admin",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? "Yes" : "No"),
  },
  {
    dataField: "approvalStatus",
    text: "Approval Status",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "isRegisteredOnApp",
    text: "Registered",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? "Yes" : "No"),
  },
  {
    dataField: "ver",
    text: "Version",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "chipId",
    text: "Chip ID",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
  {
    dataField: "server",
    text: "Server",
    sort: true,
    formatter: (cellContent, row) => (cellContent ? cellContent : "---"),
  },
]

export default lampColumns
