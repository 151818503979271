import moment from "moment/moment"

const displayTime = time => {
  const offset = -(new Date().getTimezoneOffset()) / 60

  const targetTime = moment(time).add(offset, "hours")

  return targetTime.fromNow()
}

export default displayTime
