import {
  GET_LAMPS,
  GET_LAMPS_FAIL,
  GET_LAMPS_SUCCESS,
  CREATE_LAMP,
} from "./actionTypes"

export const getLamps = (payload, history, token) => ({
  type: GET_LAMPS,
  payload: { payload, history, token },
})

export const getLampsSuccess = data => ({
  type: GET_LAMPS_SUCCESS,
  payload: data,
})

export const getLampsFail = error => ({
  type: GET_LAMPS_FAIL,
  payload: error,
})

export const createLamps = payload => ({
  type: CREATE_LAMP,
  payload,
})
