export const FRIENDSHIP_LAMP = "FL"
export const INFINITY = "IN"
export const INFINITY_V2 = "IN2"
export const INFINITY_VOICE = "IV"
export const INFINITY_VOICE_V2 = "IV2"
export const CUBE = "CUBE"
export const CUBE_V2 = "CUBE2"
export const CYLINDER = "CYLINDER"
export const CYLINDER_V2 = "CYLINDER2"
export const HOME = "HOME"
export const HOME_V2 = "HOME2"
export const TOWER = "TOWER"
export const TOWER_V2 = "TOWER2"
export const PULSE = "PULSE"

export const getProductTypeByVersion = version => {
  let productType = FRIENDSHIP_LAMP
  if (version) {
    const code = (+version - (+version % 1000000)) / 1000000
    switch (code) {
      case 21:
        productType = INFINITY_VOICE_V2
        break
      case 20:
        productType = INFINITY_V2
        break
      case 11:
        productType = INFINITY_VOICE
        break
      case 10:
        productType = INFINITY
        break
      case 2:
        productType = CUBE
        break
      case 3:
        productType = CYLINDER
        break
      case 4:
        productType = HOME
        break
      case 5:
        productType = TOWER
        break
      case 15:
        productType = HOME_V2
        break
      case 16:
        productType = TOWER_V2
        break
      case 14:
        productType = CYLINDER_V2
        break
      case 13:
        productType = CUBE_V2
        break
      case 17:
        productType = PULSE
        break
      default:
        productType = FRIENDSHIP_LAMP
        break
    }
  }

  return productType
}
