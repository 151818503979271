import PropTypes from "prop-types"
import React from "react"
// import { GoogleLogin } from "react-google-login"
import { FaGoogle } from "react-icons/fa"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, loginSuccess, apiError, socialLogin } from "store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { postGoogleLogin } from "helpers/backend_helper"
import { toast } from "react-toastify"
import login from "store/auth/login/reducer"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const responseGoogle = async authResult => {
    try {
      if (authResult["token"]) {
        postGoogleLogin(authResult)
          .then(res => {
            const { token } = res.payload
            localStorage.setItem("authUser", JSON.stringify(res.payload.user))
            localStorage.setItem("token", token)
            props.loginSuccess(res.payload.user)
            props.history.push("/dashboard")
          })
          .catch(err => {
            toast.error(err?.data?.error?.message ?? "Error")
          })
      } else {
        throw new Error(authResult)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useGoogleOneTapLogin({
    onSuccess: res => responseGoogle({
      token: res?.credential,
      isCode: false,
    }),
    onError: () => {
      toast.error("Login Failed")
    },
  })

  const googleLogin = useGoogleLogin({
    onSuccess: res => responseGoogle({
      token: res?.code,
      isCode: true
    }),
    onError: () => {
      toast.error("Login Failed")
    },
    flow: "auth-code"
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Flamps.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="form-horizontal">
                      <div className="my-4 text-center">
                        <div>
                          <button
                            type="button"
                            style={{ position: "relative" }}
                            className={`btn btn-primary font-weight-bold py-3 w-100`}
                            onClick={() => googleLogin()}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 30,
                                fontSize: 28,
                              }}
                            >
                              <FaGoogle />
                            </div>
                            <span>Login with FriendLamps Email </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, loginSuccess, apiError, socialLogin })(
    Login
  )
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
