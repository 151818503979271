import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import _get from "lodash/get"
import { GET_LAMPS, CREATE_LAMP } from "./actionTypes"
import { getLampsFail, getLampsSuccess } from "./actions"

import { getLampsApi, createLampApi } from "helpers/backend_helper"
import { toast } from "react-toastify"

function* fetchLamps({ payload }) {
  try {
    const response = yield call(getLampsApi, payload)
    yield put(getLampsSuccess(response.payload))
  } catch (error) {
    if (error.status === 401) {
      payload.history.push("/login")
    }
    yield put(getLampsFail(error))
  }
}

function* createLamp(payload) {
  try {
    yield call(createLampApi, payload.data)
    toast.success("Create Lamp Success!")
  } catch (error) {
    const isResponseError = _get(error, "data.error.status", false)

    if (isResponseError) {
      toast.error(error.data.error.message)
    } else {
      toast.error("Unknown Error!")
    }
  }
}

function* lampsSaga() {
  yield takeLatest(GET_LAMPS, fetchLamps)
  yield takeEvery(CREATE_LAMP, createLamp)
}

export default lampsSaga
