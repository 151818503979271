import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"

// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)
const postGoogleLogin = data => post(url.POST_LOGIN_GOOGLE, data)

// Lamps
const getLampsApi = data => {
  return post(url.GET_LAMPS, data.payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.token}`,
    },
  })
}
const createLampApi = data => post(url.CREATE_LAMPS, data)
const updateLampApi = (id, data) => put(`${url.CREATE_LAMPS}/${id}`, data)
const updateMultiColor = (groupId, data) => put(`${url.CREATE_LAMPS}/multicolor/${groupId}`, data)
const deleteLampApi = (id, data) => del(`${url.CREATE_LAMPS}/${id}`)
const pingApi = data => post(`${url.CREATE_LAMPS}/ping`, data)
const yellowApi = data => post(`${url.CREATE_LAMPS}/yellow`, data)

const updateLampPermissionApi = data => post(url.UPDATE_LAMP_PERMISSION, data)

export {
  postJwtLogin,
  postGoogleLogin,
  getLampsApi,
  createLampApi,
  updateLampApi,
  deleteLampApi,
  pingApi,
  yellowApi,
  updateLampPermissionApi,
  updateMultiColor
}
