export const TIMEZONE_LIST = [
  {
    timezone: "US/Alaska",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "US/Aleutian",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "US/Arizona",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "US/Central",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "US/East-Indiana",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "US/Eastern",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "US/Hawaii",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "US/Indiana-Starke",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "US/Michigan",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "US/Mountain",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "US/Pacific",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "US/Samoa",
    value: "-11",
    label: "(GMT-11:00)",
  },
  {
    timezone: "America/Adak",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "America/Anchorage",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/Anguilla",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Antigua",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Araguaina",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Buenos_Aires",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Catamarca",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/ComodRivadavia",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Cordoba",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Jujuy",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/La_Rioja",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Mendoza",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Rio_Gallegos",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Salta",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/San_Juan",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/San_Luis",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Tucuman",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Argentina/Ushuaia",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Aruba",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Asuncion",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Atikokan",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Atka",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "America/Bahia",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Bahia_Banderas",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Barbados",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Belem",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Belize",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Blanc-Sablon",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Boa_Vista",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Bogota",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Boise",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Buenos_Aires",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Cambridge_Bay",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Campo_Grande",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Cancun",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Caracas",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Catamarca",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Cayenne",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Cayman",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Chicago",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Chihuahua",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Ciudad_Juarez",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Coral_Harbour",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Cordoba",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Costa_Rica",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Creston",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Cuiaba",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Curacao",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Danmarkshavn",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "America/Dawson",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Dawson_Creek",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Denver",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Detroit",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Dominica",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Edmonton",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Eirunepe",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/El_Salvador",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Ensenada",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "America/Fort_Nelson",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Fort_Wayne",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Fortaleza",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Glace_Bay",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Godthab",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "America/Goose_Bay",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Grand_Turk",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Grenada",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Guadeloupe",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Guatemala",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Guayaquil",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Guyana",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Halifax",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Havana",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Hermosillo",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Indiana/Indianapolis",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indiana/Knox",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Indiana/Marengo",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indiana/Petersburg",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indiana/Tell_City",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Indiana/Vevay",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indiana/Vincennes",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indiana/Winamac",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Indianapolis",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Inuvik",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Iqaluit",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Jamaica",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Jujuy",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Juneau",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/Kentucky/Louisville",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Kentucky/Monticello",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Knox_IN",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Kralendijk",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/La_Paz",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Lima",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Los_Angeles",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "America/Louisville",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Lower_Princes",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Maceio",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Managua",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Manaus",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Marigot",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Martinique",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Matamoros",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Mazatlan",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Mendoza",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Menominee",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Merida",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Metlakatla",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/Mexico_City",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Miquelon",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Moncton",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Monterrey",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Montevideo",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Montreal",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Montserrat",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Nassau",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/New_York",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Nipigon",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Nome",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/Noronha",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "America/North_Dakota/Beulah",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/North_Dakota/Center",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/North_Dakota/New_Salem",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Nuuk",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "America/Ojinaga",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Panama",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Pangnirtung",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Paramaribo",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Phoenix",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Port-au-Prince",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Port_of_Spain",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Porto_Acre",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Porto_Velho",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Puerto_Rico",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Punta_Arenas",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Rainy_River",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Rankin_Inlet",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Recife",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Regina",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Resolute",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Rio_Branco",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Rosario",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Santa_Isabel",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "America/Santarem",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Santiago",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Santo_Domingo",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Sao_Paulo",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "America/Scoresbysund",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "America/Shiprock",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Sitka",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/St_Barthelemy",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/St_Johns",
    value: "-3.5",
    label: "(GMT-03:30)",
  },
  {
    timezone: "America/St_Kitts",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/St_Lucia",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/St_Thomas",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/St_Vincent",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Swift_Current",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Tegucigalpa",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Thule",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Thunder_Bay",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Tijuana",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "America/Toronto",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "America/Tortola",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Vancouver",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "America/Virgin",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "America/Whitehorse",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "America/Winnipeg",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "America/Yakutat",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "America/Yellowknife",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "Africa/Abidjan",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Accra",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Addis_Ababa",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Algiers",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Asmara",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Asmera",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Bamako",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Bangui",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Banjul",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Bissau",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Blantyre",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Brazzaville",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Bujumbura",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Cairo",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Casablanca",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Ceuta",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Conakry",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Dakar",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Dar_es_Salaam",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Djibouti",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Douala",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/El_Aaiun",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Freetown",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Gaborone",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Harare",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Johannesburg",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Juba",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Kampala",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Khartoum",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Kigali",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Kinshasa",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Lagos",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Libreville",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Lome",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Luanda",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Lubumbashi",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Lusaka",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Malabo",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Maputo",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Maseru",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Mbabane",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Mogadishu",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Monrovia",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Nairobi",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Africa/Ndjamena",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Niamey",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Nouakchott",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Ouagadougou",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Porto-Novo",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Sao_Tome",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Timbuktu",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Africa/Tripoli",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Africa/Tunis",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Africa/Windhoek",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Antarctica/Casey",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Antarctica/Davis",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Antarctica/DumontDUrville",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Antarctica/Macquarie",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Antarctica/Mawson",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Antarctica/McMurdo",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Antarctica/Palmer",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "Antarctica/Rothera",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "Antarctica/South_Pole",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Antarctica/Syowa",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Antarctica/Troll",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Antarctica/Vostok",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Arctic/Longyearbyen",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Asia/Aden",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Almaty",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Amman",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Anadyr",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Asia/Aqtau",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Aqtobe",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Ashgabat",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Ashkhabad",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Atyrau",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Baghdad",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Bahrain",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Baku",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Asia/Bangkok",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Barnaul",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Beirut",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Bishkek",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Brunei",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Calcutta",
    value: "+5.5",
    label: "(GMT+05:30)",
  },
  {
    timezone: "Asia/Chita",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Choibalsan",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Chongqing",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Chungking",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Colombo",
    value: "+5.5",
    label: "(GMT+05:30)",
  },
  {
    timezone: "Asia/Dacca",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Damascus",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Dhaka",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Dili",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Dubai",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Asia/Dushanbe",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Famagusta",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Gaza",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Harbin",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Hebron",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Ho_Chi_Minh",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Hong_Kong",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Hovd",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Irkutsk",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Istanbul",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Jakarta",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Jayapura",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Jerusalem",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Kabul",
    value: "+4.5",
    label: "(GMT+04:30)",
  },
  {
    timezone: "Asia/Kamchatka",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Asia/Karachi",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Kashgar",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Kathmandu",
    value: "+5.75",
    label: "(GMT+05:45)",
  },
  {
    timezone: "Asia/Katmandu",
    value: "+5.75",
    label: "(GMT+05:45)",
  },
  {
    timezone: "Asia/Khandyga",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Kolkata",
    value: "+5.5",
    label: "(GMT+05:30)",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Kuala_Lumpur",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Kuching",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Kuwait",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Macao",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Macau",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Magadan",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Asia/Makassar",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Manila",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Muscat",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Asia/Nicosia",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Novokuznetsk",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Novosibirsk",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Omsk",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Oral",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Phnom_Penh",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Pontianak",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Pyongyang",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Qatar",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Qostanay",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Qyzylorda",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Rangoon",
    value: "+6.5",
    label: "(GMT+06:30)",
  },
  {
    timezone: "Asia/Riyadh",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Asia/Saigon",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Sakhalin",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Asia/Samarkand",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Seoul",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Shanghai",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Singapore",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Srednekolymsk",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Asia/Taipei",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Tashkent",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Tbilisi",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Asia/Tehran",
    value: "+3.5",
    label: "(GMT+03:30)",
  },
  {
    timezone: "Asia/Tel_Aviv",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Asia/Thimbu",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Thimphu",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Tokyo",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Tomsk",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Ujung_Pandang",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Ulaanbaatar",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Ulan_Bator",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Asia/Urumqi",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Asia/Ust-Nera",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Asia/Vientiane",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Asia/Vladivostok",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Asia/Yakutsk",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Asia/Yangon",
    value: "+6.5",
    label: "(GMT+06:30)",
  },
  {
    timezone: "Asia/Yekaterinburg",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Asia/Yerevan",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Atlantic/Azores",
    value: "-1",
    label: "(GMT-01:00)",
  },
  {
    timezone: "Atlantic/Bermuda",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "Atlantic/Canary",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/Cape_Verde",
    value: "-1",
    label: "(GMT-01:00)",
  },
  {
    timezone: "Atlantic/Faeroe",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/Faroe",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/Jan_Mayen",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Atlantic/Madeira",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/Reykjavik",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/South_Georgia",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "Atlantic/St_Helena",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Atlantic/Stanley",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "Australia/ACT",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Adelaide",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Australia/Brisbane",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Broken_Hill",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Australia/Canberra",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Currie",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Darwin",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Australia/Eucla",
    value: "+8.75",
    label: "(GMT+08:45)",
  },
  {
    timezone: "Australia/Hobart",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/LHI",
    value: "+10.5",
    label: "(GMT+10:30)",
  },
  {
    timezone: "Australia/Lindeman",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Lord_Howe",
    value: "+10.5",
    label: "(GMT+10:30)",
  },
  {
    timezone: "Australia/Melbourne",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/North",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Australia/NSW",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Perth",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Australia/Queensland",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/South",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Australia/Sydney",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Tasmania",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/Victoria",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Australia/West",
    value: "+8",
    label: "(GMT+08:00)",
  },
  {
    timezone: "Australia/Yancowinna",
    value: "+9.5",
    label: "(GMT+09:30)",
  },
  {
    timezone: "Brazil/Acre",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "Brazil/DeNoronha",
    value: "-2",
    label: "(GMT-02:00)",
  },
  {
    timezone: "Brazil/East",
    value: "-3",
    label: "(GMT-03:00)",
  },
  {
    timezone: "Brazil/West",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "Canada/Atlantic",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "Canada/Central",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Canada/Eastern",
    value: "-5",
    label: "(GMT-05:00)",
  },
  {
    timezone: "Canada/Mountain",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "Canada/Newfoundland",
    value: "-3.5",
    label: "(GMT-03:30)",
  },
  {
    timezone: "Canada/Pacific",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "Canada/Saskatchewan",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Canada/Yukon",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "Chile/Continental",
    value: "-4",
    label: "(GMT-04:00)",
  },
  {
    timezone: "Chile/EasterIsland",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Europe/Amsterdam",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Andorra",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Astrakhan",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Europe/Athens",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Belfast",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Belgrade",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Berlin",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Bratislava",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Brussels",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Bucharest",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Budapest",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Busingen",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Chisinau",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Copenhagen",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Dublin",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Gibraltar",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Guernsey",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Helsinki",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Isle_of_Man",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Istanbul",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Jersey",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Kaliningrad",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Kiev",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Kirov",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Kyiv",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Lisbon",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Ljubljana",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/London",
    value: "0",
    label: "(GMT+00:00)",
  },
  {
    timezone: "Europe/Luxembourg",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Madrid",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Malta",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Mariehamn",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Minsk",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Monaco",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Moscow",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Nicosia",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Oslo",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Paris",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Podgorica",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Prague",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Riga",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Rome",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Samara",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Europe/San_Marino",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Sarajevo",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Saratov",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Europe/Simferopol",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Skopje",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Sofia",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Stockholm",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Tallinn",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Tirane",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Tiraspol",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Ulyanovsk",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Europe/Uzhgorod",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Vaduz",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Vatican",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Vienna",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Vilnius",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Volgograd",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Europe/Warsaw",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Zagreb",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Europe/Zaporozhye",
    value: "+2",
    label: "(GMT+02:00)",
  },
  {
    timezone: "Europe/Zurich",
    value: "+1",
    label: "(GMT+01:00)",
  },
  {
    timezone: "Indian/Antananarivo",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Indian/Chagos",
    value: "+6",
    label: "(GMT+06:00)",
  },
  {
    timezone: "Indian/Christmas",
    value: "+7",
    label: "(GMT+07:00)",
  },
  {
    timezone: "Indian/Cocos",
    value: "+6.5",
    label: "(GMT+06:30)",
  },
  {
    timezone: "Indian/Comoro",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Indian/Kerguelen",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Indian/Mahe",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Indian/Maldives",
    value: "+5",
    label: "(GMT+05:00)",
  },
  {
    timezone: "Indian/Mauritius",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Indian/Mayotte",
    value: "+3",
    label: "(GMT+03:00)",
  },
  {
    timezone: "Indian/Reunion",
    value: "+4",
    label: "(GMT+04:00)",
  },
  {
    timezone: "Mexico/BajaNorte",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "Mexico/BajaSur",
    value: "-7",
    label: "(GMT-07:00)",
  },
  {
    timezone: "Mexico/General",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Pacific/Apia",
    value: "+13",
    label: "(GMT+13:00)",
  },
  {
    timezone: "Pacific/Auckland",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Bougainville",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Chatham",
    value: "+2.75",
    label: "(GMT+12:45)",
  },
  {
    timezone: "Pacific/Chuuk",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Pacific/Easter",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Pacific/Efate",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Enderbury",
    value: "+13",
    label: "(GMT+13:00)",
  },
  {
    timezone: "Pacific/Fakaofo",
    value: "+13",
    label: "(GMT+13:00)",
  },
  {
    timezone: "Pacific/Fiji",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Funafuti",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Galapagos",
    value: "-6",
    label: "(GMT-06:00)",
  },
  {
    timezone: "Pacific/Gambier",
    value: "-9",
    label: "(GMT-09:00)",
  },
  {
    timezone: "Pacific/Guadalcanal",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Guam",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Pacific/Honolulu",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "Pacific/Johnston",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "Pacific/Kanton",
    value: "+13",
    label: "(GMT+13:00)",
  },
  {
    timezone: "Pacific/Kiritimati",
    value: "+14",
    label: "(GMT+14:00)",
  },
  {
    timezone: "Pacific/Kosrae",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Kwajalein",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Majuro",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Marquesas",
    value: "-9.5",
    label: "(GMT-09:30)",
  },
  {
    timezone: "Pacific/Midway",
    value: "-11",
    label: "(GMT-11:00)",
  },
  {
    timezone: "Pacific/Nauru",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Niue",
    value: "-11",
    label: "(GMT-11:00)",
  },
  {
    timezone: "Pacific/Norfolk",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Noumea",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Pago_Pago",
    value: "-11",
    label: "(GMT-11:00)",
  },
  {
    timezone: "Pacific/Palau",
    value: "+9",
    label: "(GMT+09:00)",
  },
  {
    timezone: "Pacific/Pitcairn",
    value: "-8",
    label: "(GMT-08:00)",
  },
  {
    timezone: "Pacific/Pohnpei",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Ponape",
    value: "+11",
    label: "(GMT+11:00)",
  },
  {
    timezone: "Pacific/Port_Moresby",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Pacific/Rarotonga",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "Pacific/Saipan",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Pacific/Samoa",
    value: "-11",
    label: "(GMT-11:00)",
  },
  {
    timezone: "Pacific/Tahiti",
    value: "-10",
    label: "(GMT-10:00)",
  },
  {
    timezone: "Pacific/Tarawa",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Tongatapu",
    value: "+13",
    label: "(GMT+13:00)",
  },
  {
    timezone: "Pacific/Truk",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: "Pacific/Wake",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Wallis",
    value: "+12",
    label: "(GMT+12:00)",
  },
  {
    timezone: "Pacific/Yap",
    value: "+10",
    label: "(GMT+10:00)",
  },
  {
    timezone: undefined,
    value: "",
    label: "timezone",
  },
]
