import React, { useState, useCallback } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useForm } from "react-hook-form"
import { updateLampPermissionApi } from "helpers/backend_helper"
import { toast } from "react-toastify"
import _get from "lodash/get"

const UpdateLampModal = ({ isOpen, toggle }) => {
  const [loading, setLoading] = useState(false)
  const [overwrite, setOverwrite] = useState(false)
  const [showOverwrite, setShowOverwrite] = useState(false)
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: {
      email: "",
      macId: "",
      groupId: "",
    },
  })

  const onSubmit = useCallback(
    values => {
      setLoading(true)
      updateLampPermissionApi({
        ...values,
        overwrite,
        macId: values.macId.replaceAll(" ", ""),
        groupId: values.groupId.replaceAll(" ", ""),
      })
        .then(res => {
          const isErrorStatus = _get(res, "data.error.status", false)
          if (!isErrorStatus) {
            toast.success("Update Lamp Success!")
            toggle()
          }
        })
        .catch(err => {
          const isErrorStatus = _get(err, "data.error.status", false)
          if (isErrorStatus) {
            if (
              err.data.error.message ===
              "Lamp is registered but not to the email field above"
            ) {
              setShowOverwrite(true)
            }
            toast.error(err.data.error.message)
          } else {
            toast.error(`${err.status} - ${err.statusText}`)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [overwrite]
  )

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabindex="-1"
      toggle={toggle}
      backdrop="static"
      onClosed={() => {
        setOverwrite(false)
        setShowOverwrite(false)
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
        <ModalHeader toggle={toggle}>Update Lamp</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">User email</label>
                <div>
                  <input
                    className="form-control"
                    name="email"
                    type="text"
                    ref={register()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">Mac ID</label>
                <div>
                  <input
                    className="form-control"
                    name="macId"
                    type="text"
                    ref={register()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">Group ID</label>
                <div>
                  <input
                    className="form-control"
                    name="groupId"
                    type="text"
                    ref={register()}
                    onBlur={e => {}}
                  />
                </div>
              </div>
            </div>
            {showOverwrite && (
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      id="overwrite"
                      className="form-check-input"
                      name="overwrite"
                      type="checkbox"
                      checked={overwrite}
                      onChange={e => {
                        setOverwrite(e.target.checked)
                      }}
                    />
                    <label htmlFor="overwrite" className="form-check-label">
                      Overwrite lamp owner
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default UpdateLampModal
