import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import _get from "lodash/get"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postJwtLogin } from "../../../helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      username: user.username,
      password: user.password,
    })

    const { token } = response.payload

    localStorage.setItem("authUser", JSON.stringify(response.payload.user))
    localStorage.setItem("token", token)
    yield put(loginSuccess(user))
    history.push("/dashboard")
  } catch (error) {
    if (error.status === 400) {
      const errorMessage = _get(error, "data.error.message", "Error")
      yield put(apiError(errorMessage))
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
