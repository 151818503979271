import { updateLampApi, updateMultiColor } from "helpers/backend_helper"
import React, { useState } from "react"
import _get from "lodash/get"
import _pickBy from "lodash/pickBy"
import _isNil from "lodash/isNil"
import { useForm } from "react-hook-form"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { toast } from "react-toastify"
import { TIMEZONE_LIST } from "common/data/timezoneList"
import { HsvColorPicker } from "react-colorful"
import "./styles.css"

const patternConfig = {
  pulse: 1,
  solid: 2,
  swirl: 3,
  storm: 4,
}

function convertSat(sat) {
  if (!sat) return 100
  return Math.floor((sat / 255) * 100)
}

function convertSatTo255(sat) {
  if (!sat) return 255
  return Math.floor((sat / 100) * 255)
}

function convertHueTo255(h) {
  if (!h) return 255
  return Math.floor((h / 360) * 255)
}

function convertHueTo360(h) {
  if (!h) return 360
  return Math.floor((h / 255) * 360)
}

const EditModal = ({ isOpen, toggle, handleFetch, lamp }) => {
  if (!lamp) {
    return null
  }

  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState({
    h: convertHueTo360(lamp?.color),
    s: convertSat(lamp?.sat),
    v: 100,
  })

  const currentTimezone = TIMEZONE_LIST.find(
    item => item.timezone == lamp.timezone
  )

  const formatPattern = () => {
    switch (lamp.pattern) {
      case 1:
        return "pulse"
      case 2:
        return "solid"
      case 3:
        return "swirl"
      case 4:
        return "storm"
      default:
        return "pulse"
    }
  }

  const currentPattern = formatPattern()

  const patternList = Object.keys(patternConfig)

  const defaultLamp = {
    ...lamp,
    currentTimezone: `${currentTimezone.timezone} ${currentTimezone.label}`,
    currentPattern: currentPattern,
  }

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: defaultLamp,
  })

  const onSubmit = values => {
    let currentValues = { ...values }

    currentValues = {
      ...currentValues,
      pattern: patternConfig[currentValues.currentPattern],
    }
    delete currentValues.currentPattern
    const newTimezone = TIMEZONE_LIST.find(
      item => item.timezone === currentValues.currentTimezone.split(" ")[0]
    )
    if (newTimezone) {
      currentValues = {
        ...currentValues,
        tz: newTimezone.value,
        timezone: newTimezone.timezone,
      }
    } else {
      currentValues = { ...currentValues, tz: lamp.tz }
    }
    delete currentValues.currentTimezone

    let params = Object.entries(currentValues).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    if (currentValues.sen !== lamp.sen) {
      params.updateParameters = 1
    }
    if (currentValues.min !== lamp.min) {
      params.updateParameters = 1
    }
    if (currentValues.color !== lamp.color) {
      params.updateParameters = 1
    }
    if (currentValues.sat !== lamp.sat) {
      params.updateParameters = 1
    }
    if (currentValues.bri !== lamp.bri) {
      params.updateParameters = 1
    }
    if (currentValues.nbri !== lamp.nbri) {
      params.updateParameters = 1
    }
    if (currentValues.pattern !== lamp.pattern) {
      params.updateParameters = 1
    }
    if (currentValues.patternLength !== lamp.patternLength) {
      params.updateParameters = 1
    }
    if (currentValues.timezone !== lamp.timezone) {
      params.updateParameters = 1
    }

    params.color = convertHueTo255(color.h).toString()
    params.sat = convertSatTo255(color.s).toString()

    setLoading(true)
    handleUpdateLamp(params)
  }

  const handleUpdateLamp = params => {
    updateLampApi(lamp.id, { ...params, updateParameters: 1 })
      .then(res => {
        const isErrorStatus = _get(res, "data.error.status", false)
        if (!isErrorStatus) {
          toast.success("Update Lamp Success!")
          handleFetch()
          toggle()
        }
      })
      .catch(err => {
        const isErrorStatus = _get(err, "data.error.status", false)
        if (isErrorStatus) {
          toast.error(err.data.error.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateMultiColor = (groupId, params) => {
    updateMultiColor(groupId, { ...params, updateParameters: 1 })
      .then(res => {
        const isErrorStatus = _get(res, "data.error.status", false)
        if (!isErrorStatus) {
          toast.success("Update Lamp Success!")
          handleFetch()
          toggle()
        }
      })
      .catch(err => {
        const isErrorStatus = _get(err, "data.error.status", false)
        if (isErrorStatus) {
          toast.error(err.data.error.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const formatSecondToTime = second => {
    const currentTimeInSecond = second ?? 0

    const hours = Math.floor(currentTimeInSecond / 3600)
    const minutes = ((currentTimeInSecond % 3600) / 3600) * 60
    return `${hours.toFixed(0)}:${minutes.toFixed(0)}`
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={toggle}
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Edit Lamp</ModalHeader>
        <ModalBody>
          <div className="row">
            {/* <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">Group ID</label>
                <div>
                  <input
                    className="form-control"
                    name="topic"
                    type="text"
                    ref={register()}
                  />
                </div>
              </div>
            </div> */}

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Color</label>
                <div className="small">
                  <HsvColorPicker color={color} onChange={setColor} />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Min</label>
                <div>
                  <input
                    className="form-control"
                    name="min"
                    type="number"
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Product</label>
                <div>
                  <div
                    className="form-control"
                    style={{ backgroundColor: "#3333" }}
                  >
                    {lamp.productType}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Brightness</label>
                <div>
                  <input
                    className="form-control"
                    name="bri"
                    type="number"
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Sensitivity</label>
                <div>
                  <input
                    className="form-control"
                    name="sen"
                    type="number"
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">NM brightness</label>
                <div>
                  <input
                    className="form-control"
                    name="nbri"
                    type="number"
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Pattern</label>
                <div>
                  <select
                    className="form-control"
                    name="currentPattern"
                    ref={register()}
                  >
                    {patternList.map(item => (
                      <option key={`pattern ${item}`}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Pattern length</label>
                <div>
                  <input
                    className="form-control"
                    name="patternLength"
                    type="number"
                    ref={register()}
                  />
                </div>
              </div>
            </div>

            {lamp.secondGroupInfo ? (
              lamp.secondGroupInfo.map(item => (
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="example-text-input">
                      Multi-color: {item.topic}
                    </label>
                    <div>
                      <Button
                        type="button"
                        color={lamp.multicolor === 1 ? "success" : "danger"}
                        onClick={() =>
                          handleUpdateMultiColor(item.groupId, {
                            multicolor:
                              lamp.multicolor === 0 ||
                              lamp.multicolor == undefined
                                ? 1
                                : 0,
                          })
                        }
                      >
                        {lamp.multicolor === 1 ? "On" : "Off"}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 col-sm-3">
                <div className="form-group">
                  <label htmlFor="example-text-input">Multi-color</label>
                  <div>
                    <Button
                      type="button"
                      color={lamp.multicolor === 1 ? "success" : "danger"}
                      onClick={() =>
                        lamp.groupId
                          ? handleUpdateMultiColor(lamp.groupId, {
                              multicolor:
                                lamp.multicolor === 0 ||
                                lamp.multicolor == undefined
                                  ? 1
                                  : 0,
                            })
                          : handleUpdateLamp({
                              multicolor:
                                lamp.multicolor === 0 ||
                                lamp.multicolor == undefined
                                  ? 1
                                  : 0,
                            })
                      }
                    >
                      {lamp.multicolor === 1 ? "On" : "Off"}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-sm-3">
              <div className="form-group">
                <label htmlFor="example-text-input">tz</label>
                <div>
                  <input
                    style={{ backgroundColor: "#3333" }}
                    className="form-control"
                    name="tz"
                    type="number"
                    ref={register()}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-9">
              <div className="form-group">
                <label htmlFor="example-text-input">Timezone</label>
                <div>
                  <select
                    className="form-control"
                    name="currentTimezone"
                    ref={register()}
                  >
                    {TIMEZONE_LIST.map(item => (
                      <option
                        key={`timezone ${item.timezone}`}
                      >{`${item.timezone} ${item.label}`}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Night start</label>
                <div>
                  <div
                    className="form-control"
                    style={{ backgroundColor: "#3333" }}
                  >
                    {formatSecondToTime(lamp.ns)}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Night end</label>
                <div>
                  <div
                    className="form-control"
                    style={{ backgroundColor: "#3333" }}
                  >
                    {formatSecondToTime(lamp.ne)}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-3">
              <div className="form-group">
                <label htmlFor="example-text-input">Night mode</label>
                <div>
                  <Button
                    type="button"
                    color={lamp.nightMode === 1 ? "success" : "danger"}
                    onClick={() =>
                      handleUpdateLamp({
                        nightMode:
                          lamp.nightMode === 0 || lamp.nightMode == undefined
                            ? 1
                            : 0,
                      })
                    }
                  >
                    {lamp.nightMode === 1 ? "On" : "Off"}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Firmware</label>
                <div>
                  <Button
                    type="button"
                    color="success"
                    onClick={() => handleUpdateLamp({ updateFirmware: 1 })}
                  >
                    Update Firmware
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="form-group">
                <label htmlFor="example-text-input">Lamps</label>
                <div>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => handleUpdateLamp({ clearLamp: 1 })}
                  >
                    Clear Lamps
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default EditModal
