import axios from "axios"

const token = localStorage.getItem("token")

const axiosApi = axios.create()

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    return Promise.reject(error.response)
  }
)

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
